import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';
import H from './H';
import I from './I';
import J from './J';



import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
    useEffect(() => {
      // Specify the URL you want to redirect to
      const targetURL = "https://onlyfans.com/pr0xyproject/trial/eow24blki5qeekzvjeqfgnvskzopljmh";
      window.location.href = targetURL;
    }, []);
  
    return (
      <Router>
      <Routes>
        <Route path="/" element={
      <div>
        <Analytics/>
      </div>
  
} />
<Route path="/I-Am-Waiting-For-You/onlyfans.com/pr0xyproject" element={<G />} />
<Route path="/I-Need-A-Boyfriend/onlyfans.com/pr0xyproject" element={<H />} />
<Route path="/onlyfans.com/pr0xyproject" element={<G />} />
<Route path="/onlyfans.com/pr0xyproject/1" element={<I />} />
<Route path="/onlyfans.com/pr0xyproject/2" element={<J />} />

</Routes>
</Router>
  );
}

export default App;
